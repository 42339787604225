import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { strapiApi } from "../../api";
import NewsletterPopup from "../../components/NewsletterPopup/NewsletterPopup";
import SEOHead from "../../components/SEOHead";
import "./HomePage.css";

const HomePage = () => {
  const scrollRef = useRef(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isVideoHovered, setIsVideoHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const [events, setEvents] = useState([]);

  const lessons = [
    {
      name: "Technique",
      description:
        "Master fundamental martial arts techniques and refine your form.",
    },
    {
      name: "Kata",
      description:
        "Learn and perfect traditional kata sequences to enhance your skills.",
    },
    {
      name: "Self-Defense",
      description:
        "Develop practical self-defense skills for real-world situations.",
    },
    {
      name: "Iken",
      description:
        "Explore the philosophical aspects of martial arts and their application in daily life.",
    },
    {
      name: "Conditioning",
      description:
        "Improve your physical fitness and stamina with martial arts-specific exercises.",
    },
    {
      name: "Speed",
      description:
        "Enhance your reaction time and movement speed for quick, effective techniques.",
    },
    {
      name: "Flexibility",
      description:
        "Increase your range of motion and prevent injuries through targeted stretching routines.",
    },
  ];

  const testimonials = [
    {
      text: "Shihan Tom Callahan is a true Martial Artist. I've enjoyed training with him and his many talented students over the years. I'm proud to call him a friend.",
      author: "Dolph Lundgren",
      description: "Actor, Director, Martial Artist - 4th Dan Black Belt",
      image: "/images/dolph-lundgren.jpg",
    },
    {
      text: "Sensei Callahan is an internationally respected Martial Artist and Instructor. The Machida's and Sensei Callahan share the same Budo philosophy. OSU!",
      author: "Chinzo Machida",
      description: "Professional MMA Fighter, Karate Master",
      image: "/images/chinzo-machida.jpg",
    },
    {
      text: "Sensei Tom's approach to wellness combines both the East and West philosophies. He helped me not only get in great shape, but I have increased energy and mental clarity, which has had a positive impact on my daily work life.",
      author: "Stefano Curti",
      description: "Chief Brand Officer of Coty",
      image: "/images/stefano-curti.jpg",
    },
    {
      text: "Shihan Tom embodies the true spirit of Kyokushin, offering authentic and transformative training that empowers students to reach new heights. His seminars are a testament to the power of dedication and passion, inspiring a new generation of practitioners to embrace the art's full potential.",
      author: "Daisy Lang",
      description: "Three Division Boxing World Champion",
      image: "/images/daisy-lang.jpg",
    },
    {
      text: "I call Shihan Tom my older brother as well because he is someone I look up and aspire to be. He represents what the budo and Kyokushin spirit is about. Train hard, never give up, being a good human being and thinking of others.",
      author: "Judd Reid",
      description: "Completed 100-Man Kyokushin Kumite",
      image: "/images/judd-reid.jpg",
    },
  ];

  useEffect(() => {
    // Handle video autoplay
    const video = document.querySelector(".background-video");
    if (video) {
      // Function to attempt playing the video
      const attemptPlay = () => {
        video.play().catch((error) => {
          console.log("Auto-play was prevented");
        });
      };

      // Try to play when video is loaded
      video.addEventListener("loadeddata", () => {
        setIsVideoLoaded(true);
        attemptPlay();
      });

      // Try to play when document receives user interaction
      document.addEventListener("touchstart", attemptPlay, {
        once: true,
      });

      return () => {
        document.removeEventListener("touchstart", attemptPlay);
      };
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    const philosophySection = document.querySelector(".philosophy");
    const appPromoSection = document.querySelector(".app-promo-content");

    if (philosophySection) philosophySection.classList.add("visible");
    if (appPromoSection) appPromoSection.classList.add("visible");

    const handleScroll = () => {
      const windowHeight = window.innerHeight;

      if (philosophySection) {
        const philosophySectionTop =
          philosophySection.getBoundingClientRect().top;
        if (philosophySectionTop < windowHeight * 0.75) {
          philosophySection.classList.add("fade-in");
        }
      }

      if (appPromoSection) {
        const appPromoSectionTop =
          appPromoSection.getBoundingClientRect().top;
        if (appPromoSectionTop < windowHeight * 0.75) {
          appPromoSection.classList.add("fade-in");
        }
      }
    };

    setTimeout(() => {
      handleScroll();
      window.addEventListener("scroll", handleScroll);
    }, 100);

    const scrollContainer = scrollRef.current;

    const updateScrollWidth = () => {
      if (scrollContainer) {
        setScrollWidth(scrollContainer.scrollWidth / 2);
      }
    };

    updateScrollWidth();
    window.addEventListener("resize", updateScrollWidth);

    let animationId;
    const scrollLeftToRight = () => {
      if (!isHovering && scrollContainer) {
        scrollContainer.scrollLeft += 1;
        if (scrollContainer.scrollLeft >= scrollWidth) {
          scrollContainer.scrollLeft = 0;
        }
      }
      animationId = requestAnimationFrame(scrollLeftToRight);
    };

    animationId = requestAnimationFrame(scrollLeftToRight);

    const testimonialTimer = setInterval(() => {
      setIsSliding(true);
      setTimeout(() => {
        setCurrentTestimonial(
          (prev) => (prev + 1) % testimonials.length
        );
        setIsSliding(false);
      }, 500);
    }, 7000);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateScrollWidth);
      cancelAnimationFrame(animationId);
      clearInterval(testimonialTimer);
    };
  }, [isHovering, scrollWidth, testimonials.length]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await strapiApi.get("/api/events?populate=*");

        if (response.data && response.data.data) {
          setEvents(response.data.data.slice(0, 3)); // Get first 3 events
        } else {
          console.error(
            "Unexpected events data structure:",
            response.data
          );
        }
      } catch (err) {
        console.error("Error fetching events:", err);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="home-page" id="main-content">
      <SEOHead
        title="World Budo Arts - Master the Way of Budo | Martial Arts Training"
        description="Begin your martial arts journey with World Budo Arts. Learn authentic Kyokushin Karate from Shihan Tom Callahan anytime, anywhere. Join our global community of martial artists."
        path="/"
      />
      <section className="hero">
        <video
          autoPlay
          loop
          muted
          playsInline
          className={`background-video ${isVideoLoaded ? "loaded" : ""
            }`}
          poster="/videos/hero-poster.jpg" // Add a poster image
        >
          <source
            src="/videos/hero-background.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div className="hero-content">
          <h1>Study the Way of Budo</h1>
          <p>
            Embark on your path to mastery with{" "}
            <a href="/About" className="shihan_link">
              <span className="shihan">Shihan Tom</span>
            </a>{" "}
            and our diverse community of martial artists.
          </p>
          <Link to="/about" className="btn btn-primary">
            Learn More
          </Link>
        </div>
      </section>

      <section className="philosophy">
        <div className="container">
          <div className="philosophy-content">
            <div className="philosophy-text">
              <h2>Our Philosophy</h2>
              <p>
                World Budo Arts was created by Shihan Tom
                Callahan as not only a dojo, but a brand and
                idea that encapsulates the ethos of a training
                philosophy. We believe in the power of martial
                arts to transform lives, build character, and
                foster a sense of community.
              </p>
              <Link to="/about" className="btn btn-primary">
                Learn More About Us
              </Link>
            </div>
            <div className="philosophy-video">
              <div
                className={`video-wrapper ${isVideoHovered ? "hovered" : ""
                  }`}
                onMouseEnter={() => setIsVideoHovered(true)}
                onMouseLeave={() => setIsVideoHovered(false)}
              >
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/5YFWgZwywfk"
                  title="World Budo Arts Philosophy"
                  frameBorder="0"
                  loading="lazy"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ borderRadius: "10px" }}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="app-promo">
        <div className="container">
          <div className="app-promo-content">
            <div className="app-promo-image">
              <img
                src="/images/app-mockup.png"
                alt="World Budo Arts Mobile App"
              />
            </div>
            <div className="app-promo-text">
              <h2>Experience World Budo Arts</h2>
              <h3>Anytime, Anywhere</h3>
              <p>
                Download our mobile app to access exclusive
                digital martial arts lessons, connect with our
                community, and track your progress.
              </p>
              <ul>
                <li>Access to premium video lessons</li>
                <li>
                  Live streaming sessions with Shihan Tom
                  Callahan
                </li>
                <li>
                  Progress tracking and personalized training
                  plans
                </li>
                <li>Connect with fellow martial artists</li>
              </ul>
              <a
                href="https://app.store.link"
                className="btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Link to download the World Budo Arts app - coming soon"
              >
                Download Now
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="lessons">
        <div className="container">
          <h2>Our Lessons</h2>
          <div
            className="lesson-scroll"
            ref={scrollRef}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            aria-label="Automatic carousel of lessons"
          >
            <div className="lesson-container">
              {lessons.concat(lessons).map((lesson, index) => (
                <div key={index} className="lesson-card">
                  <h3>{lesson.name}</h3>
                  <p>{lesson.description}</p>
                  <Link
                    to="/dashboard"
                    className="btn btn-secondary"
                  >
                    Learn More
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials">
        <div className="container">
          <h2>Testimonials</h2>
          <div className="testimonial-content">
            <div
              className={`testimonial-text ${isSliding ? "slide-out" : "slide-in"
                }`}
            >
              <p>{testimonials[currentTestimonial].text}</p>
              <div className="testimonial-author-container">
                <p className="testimonial-author">
                  - {testimonials[currentTestimonial].author}
                </p>
                <p className="testimonial-description">
                  {
                    testimonials[currentTestimonial]
                      .description
                  }
                </p>
              </div>
            </div>
            <div
              className={`testimonial-image ${isSliding ? "slide-out" : "slide-in"
                }`}
            >
              <img
                src={testimonials[currentTestimonial].image}
                alt={testimonials[currentTestimonial].author}
              />
            </div>
          </div>
          <div className="testimonial-nav">
            {testimonials.map((_, index) => (
              <button
                key={index}
                className={`nav-dot ${index === currentTestimonial ? "active" : ""
                  }`}
                onClick={() => setCurrentTestimonial(index)}
                aria-label="Testimonial scrolling buttons"
              ></button>
            ))}
          </div>
        </div>
      </section>

      <section className="home-events">
        <div className="container">
          <h2>Upcoming Events</h2>
          <div className="home-event-list">
            {events.length === 0 ? (
              <div className="home-no-events">
                <h3>Exciting Events Coming Soon!</h3>
                <p>
                  We're currently planning our next series of
                  martial arts events and seminars.
                </p>
                <p>
                  Stay tuned for upcoming opportunities to
                  train, learn, and grow with our community.
                </p>
                <div className="home-no-events-actions">
                  <Link to="/contact" className="btn-event">
                    Get Notified
                  </Link>
                  <Link to="/blog" className="btn-event">
                    Read Our Blog
                  </Link>
                </div>
              </div>
            ) : (
              events.map((event) => (
                <div
                  key={`event-${event.id}`}
                  className="home-event-card"
                >
                  <div className="home-event-card-inner">
                    <h3 className="event-title">
                      {event.attributes.name}
                    </h3>
                    <p className="event-date">
                      Date:{" "}
                      {new Date(
                        event.attributes.date
                      ).toLocaleDateString()}
                    </p>
                    <p className="event-description">
                      {Array.isArray(
                        event.attributes.description
                      )
                        ? `${event.attributes.description[0].children[0].text.substring(
                          0,
                          100
                        )}...`
                        : typeof event.attributes
                          .description === "string"
                          ? `${event.attributes.description.substring(
                            0,
                            100
                          )}...`
                          : "No description available"}
                    </p>
                    <div className="home-event-card-button">
                      <Link
                        to="/events"
                        className="btn-event"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </section>

      <section className="cta">
        <div className="container">
          <h2>Ready to Begin Your Journey?</h2>
          <p>
            Join World Budo Arts today and discover the
            transformative power of martial arts.
          </p>
          <Link to="/contact" className="btn">
            Contact Us
          </Link>
        </div>
      </section>
      <NewsletterPopup />
    </div>
  );
};

export default HomePage;
