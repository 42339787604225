import React, { useState, useEffect, useRef } from 'react';
import { FocusOn } from 'react-focus-on';
import api from '../../api';
import './NewsletterPopup.css';

const NewsletterPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const popupRef = useRef(null);
  const emailInputRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      const hasSeenPopup = localStorage.getItem('hasSeenNewsletterPopup');
      if (!hasSeenPopup) {
        setShowPopup(true);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (showPopup && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [showPopup]);

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      const response = await api.post('/newsletter/signup', { email });
      setMessage('Thank you for subscribing!');
      setSubmitted(true);
      setEmail('');
    } catch (error) {
      setMessage(
        error.response?.data?.message || 
        'An error occurred. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setShowPopup(false);
    localStorage.setItem('hasSeenNewsletterPopup', 'true');
    // Return focus to the last focused element before the popup opened
    document.getElementById('main-content').focus();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      handleClose();
    }
  };

  if (!showPopup) return null;

  return (
    <FocusOn
      onEscapeKey={handleClose}
      onClickOutside={handleClose}
      returnFocus={true}
    >
      <div
        className="newsletter-popup"
        role="dialog"
        aria-modal="true"
        aria-labelledby="newsletter-title"
        aria-describedby="newsletter-description"
        onKeyDown={handleKeyDown}
        ref={popupRef}
      >
        <div className="newsletter-content">
          <button
            className="close-button"
            onClick={handleClose}
            aria-label="Close newsletter popup"
          >
            &times;
          </button>
          {!submitted ? (
            <>
              <h2 id="newsletter-title">Join Our World Budo Arts Community</h2>
              <p id="newsletter-description">
                Subscribe to receive updates about classes, events, and martial arts insights!
              </p>
              <form 
                onSubmit={handleNewsletterSubmit}
                aria-label="Newsletter signup form"
              >
                <div className="form-group">
                  <label htmlFor="newsletter-email" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="newsletter-email"
                    ref={emailInputRef}
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    disabled={isLoading}
                    aria-required="true"
                    aria-invalid={message.includes('error')}
                    aria-describedby={message ? 'newsletter-message' : undefined}
                  />
                </div>
                <button 
                  type="submit" 
                  className="submit-button" 
                  disabled={isLoading}
                  aria-busy={isLoading}
                >
                  {isLoading ? 'Subscribing...' : 'Subscribe'}
                </button>
              </form>
              {message && (
                <p 
                  id="newsletter-message"
                  className={`message ${message.includes('error') ? 'error' : 'success'}`}
                  role={message.includes('error') ? 'alert' : 'status'}
                >
                  {message}
                </p>
              )}
            </>
          ) : (
            <div className="success-message" role="status">
              <h2>Thank You!</h2>
              <p>You've successfully subscribed to our newsletter.</p>
              <button 
                onClick={handleClose}
                className="btn btn-primary"
                aria-label="Close popup and return to homepage"
              >
                Return to Homepage
              </button>
            </div>
          )}
        </div>
      </div>
    </FocusOn>
  );
};

export default NewsletterPopup;