import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';
const STRAPI_URL = process.env.REACT_APP_STRAPI_URL || 'http://localhost:1337';
const STRAPI_API_TOKEN = process.env.REACT_APP_STRAPI_TOKEN;

// Create axios instance for backend API
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

// Add request interceptor for API debugging
api.interceptors.request.use(
  (config) => {
    console.log('API Request:', {
      url: config.baseURL + config.url,
      method: config.method,
      headers: config.headers
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for API debugging
api.interceptors.response.use(
  (response) => {
    console.log('API Response:', {
      status: response.status,
      url: response.config.url,
      data: !!response.data
    });
    return response;
  },
  (error) => {
    console.error('API Request failed:', {
      url: error.config?.baseURL + error.config?.url,
      status: error.response?.status,
      data: error.response?.data
    });
    return Promise.reject(error);
  }
);

// Create axios instance for Strapi
const strapiApi = axios.create({
  baseURL: STRAPI_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Function to get the current Strapi token
const getStrapiToken = () => {
  // Update to check for the correct environment variable name
  const token = process.env.REACT_APP_STRAPI_TOKEN;
  if (!token) {
    console.error('STRAPI_TOKEN is not set in environment variables');
    console.log('Available env vars:', Object.keys(process.env).filter(key => key.startsWith('REACT_APP_')));
  }
  return token;
};

strapiApi.interceptors.request.use(
  (config) => {
    const token = getStrapiToken();
    
    if (!token) {
      console.error('No Strapi token available');
      return Promise.reject(new Error('Strapi API Token is missing'));
    }

    // Set the Authorization header
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    console.error('Strapi Request Error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor for Strapi
strapiApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('Strapi Response Error:', {
      status: error.response?.status,
      message: error.response?.data?.error?.message || error.message,
      url: error.config?.url
    });

    if (error.response?.status === 401) {
      console.error('Authentication failed - Token details:', {
        tokenExists: !!getStrapiToken(),
        environment: process.env.NODE_ENV
      });
    }

    return Promise.reject(error);
  }
);

// Modified fetch functions with better error handling
export const fetchBlogPosts = async () => {
  try {
    if (!getStrapiToken()) {
      throw new Error('Strapi token is not configured');
    }

    const response = await strapiApi.get('/api/blog-posts?populate=*');
    if (!response.data) {
      throw new Error('No data received from API');
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching blog posts:', error);
    throw error;
  }
};

export const fetchBlogPost = async (slug) => {
  try {
    if (!getStrapiToken()) {
      throw new Error('Strapi token is not configured');
    }

    const response = await strapiApi.get(
      `/api/blog-posts?filters[slug][$eq]=${slug}&populate=*`
    );
    
    if (!response.data?.data?.length) {
      throw new Error('Blog post not found');
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching blog post:', {
      error,
      slug,
      hasToken: !!getStrapiToken()
    });
    throw error;
  }
};

export const fetchEvents = async () => {
  try {
    if (!getStrapiToken()) {
      throw new Error('Strapi token is not configured');
    }

    const response = await strapiApi.get('/api/events?populate=*');
    console.log('Events response:', response.data);
    
    if (!response.data) {
      throw new Error('No events data received');
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const fetchEvent = async (id) => {
  try {
    if (!getStrapiToken()) {
      throw new Error('Strapi token is not configured');
    }

    const response = await strapiApi.get(`/api/events/${id}?populate=*`);
    
    if (!response.data) {
      throw new Error('Event not found');
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching event:', error);
    throw error;
  }
};

export const fetchGalleryItems = async () => {
  try {
    if (!getStrapiToken()) {
      throw new Error('Strapi token is not configured');
    }

    const response = await strapiApi.get('/api/gallery-items?populate=*');
    console.log('Gallery API Response:', response); // Debug log

    // Handle both possible response structures
    if (response?.data?.data) {
      return response.data.data;
    } else if (response?.data) {
      return response.data;
    } else {
      throw new Error('Unexpected API response structure');
    }
  } catch (error) {
    console.error('Error fetching gallery items:', {
      error,
      hasToken: !!getStrapiToken(),
      responseStructure: error.response?.data
    });
    throw error;
  }
};

export const fetchGalleryItem = async (id) => {
  try {
    if (!getStrapiToken()) {
      throw new Error('Strapi token is not configured');
    }

    const response = await strapiApi.get(`/api/gallery-items/${id}?populate=*`);
    
    if (!response.data) {
      throw new Error('Gallery item not found');
    }
    
    return response.data;
  } catch (error) {
    console.error('Error fetching gallery item:', error);
    throw error;
  }
};

export { strapiApi };
export default api;