import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaFacebook, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { strapiApi } from '../../api';
import SEOHead from '../../components/SEOHead';
import './BlogPost.css';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await strapiApi.get(
          `/api/blog-posts?filters[slug][$eq]=${slug}&populate=*`
        );
        
        if (response.data?.data?.[0]?.attributes) {
          setPost(response.data.data[0].attributes);
        } else {
          setError('Blog post not found');
        }
      } catch (err) {
        console.error('Error fetching blog post:', err);
        setError('Failed to fetch blog post. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Use 'instant' instead of 'smooth' for immediate scroll
    });
    document.body.style.scrollBehavior = 'auto';
    return () => {
      document.body.style.scrollBehavior = 'smooth';
    };
  }, [slug]);

  // Helper function to extract plain text from content blocks
  const getPlainTextContent = (content) => {
    if (!content || !Array.isArray(content)) return '';
    
    return content.map(block => {
      if (block.children) {
        return block.children.map(child => child.text || '').join(' ');
      }
      return '';
    }).join(' ').trim();
  };

  const renderBlock = (block, index) => {
    if (!block) return null;

    switch (block.type) {
      case 'paragraph':
        return block.children?.length > 0 && (
          <p key={index}>
            {block.children.map((child, childIndex) => (
              <span key={childIndex}>{child.text}</span>
            ))}
          </p>
        );
      case 'heading':
        const HeadingTag = `h${block.level || 2}`;
        return block.children?.length > 0 && (
          <HeadingTag key={index}>
            {block.children.map((child, childIndex) => (
              <span key={childIndex}>{child.text}</span>
            ))}
          </HeadingTag>
        );
      case 'list':
        const ListTag = block.style === 'ordered' ? 'ol' : 'ul';
        return (
          <ListTag key={index}>
            {block.children?.map((item, itemIndex) => (
              <li key={itemIndex}>
                {item.children?.map((child, childIndex) => (
                  <span key={childIndex}>{child.text}</span>
                ))}
              </li>
            ))}
          </ListTag>
        );
      case 'quote':
        return (
          <blockquote key={index}>
            {block.children?.map((child, childIndex) => (
              <p key={childIndex}>{child.text}</p>
            ))}
          </blockquote>
        );
      default:
        return null;
    }
  };

  const renderContent = (content) => {
    if (!content) return <p>No content available</p>;
    if (!Array.isArray(content)) return <p>{String(content)}</p>;
    return content.map((block, index) => renderBlock(block, index));
  };

  if (loading) {
    return (
      <div className="blog-post">
        <div className="blog-post__content-wrapper">
          <div className="blog-post__content-container">
            <div className="loading">Loading post...</div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="blog-post">
        <div className="blog-post__content-wrapper">
          <div className="blog-post__content-container">
            <div className="error-message">
              <p>{error || 'Post not found'}</p>
              <Link to="/blog" className="blog-post__back-link">&larr; Back to Blog</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const shareUrl = window.location.href;
  const plainTextContent = getPlainTextContent(post.content);
  const description = post.excerpt || plainTextContent.substring(0, 160);

  return (
    <div className="blog-post">
      <SEOHead 
        title={`${post.title} | World Budo Arts Blog`}
        description={description}
        path={`/blog/${slug}`}
      />
      <div className="blog-post__hero">
        <div className="blog-post__hero-container">
          <h1 className="blog-post__title">{post.title}</h1>
          <p className="blog-post__meta">
            By Shihan Tom | {new Date(post.createdAt).toLocaleDateString()}
          </p>
        </div>
      </div>

      <div className="blog-post__content-wrapper" id="main-content">
        <div className="blog-post__content-container">
          <Link to="/blog" className="blog-post__back-link">&larr; Back to Blog</Link>
          <article className="blog-post__content">
            {post.image?.data && (
              <img 
                src={`${process.env.REACT_APP_STRAPI_URL}${post.image.data.attributes.url}`}
                alt={post.title}
                className="blog-post__featured-image"
              />
            )}
            {renderContent(post.content)}
            
            <div className="blog-post__social-share">
              <h2>Share this article</h2>
              <div className="blog-post__share-buttons">
                <button 
                  onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank')}
                  className="blog-post__share-btn blog-post__share-btn--facebook"
                >
                  <FaFacebook /> Facebook
                </button>
                <button 
                  onClick={() => window.open(`https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(post.title)}`, '_blank')}
                  className="blog-post__share-btn blog-post__share-btn--x"
                >
                  <FaXTwitter /> X
                </button>
                <button 
                  onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${encodeURIComponent(post.title)}`, '_blank')}
                  className="blog-post__share-btn blog-post__share-btn--linkedin"
                >
                  <FaLinkedin /> LinkedIn
                </button>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;