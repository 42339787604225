import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchEvents } from '../../api';
import SEOHead from '../../components/SEOHead';
import './EventsPage.css';

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getEvents = async () => {
      try {
        const response = await fetchEvents();
        console.log('Events response:', response); // Debug log
        
        if (response && response.data) {
          setEvents(response.data);
        } else {
          setError('No events found');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to fetch events. Please try again later.');
        setLoading(false);
      }
    };

    getEvents();
    window.scrollTo(0, 0);
  }, []);

  const getImageUrl = (event) => {
    try {
      if (event.attributes.image?.data?.attributes?.url) {
        return `${process.env.REACT_APP_STRAPI_URL}${event.attributes.image.data.attributes.url}`;
      }
    } catch (error) {
      console.error('Error getting image URL:', error);
    }
    return "/images/event-default.jpg";
  };

  const formatDate = (dateString) => {
    try {
      return new Date(dateString).toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date not available';
    }
  };

  // Helper function to extract description text
  const getDescription = (description) => {
    if (!description) return '';
    if (typeof description === 'string') return description;
    if (Array.isArray(description)) {
      return description.map(block => {
        if (typeof block === 'string') return block;
        if (block.children && Array.isArray(block.children)) {
          return block.children.map(child => child.text || '').join('');
        }
        return '';
      }).join('\n');
    }
    return '';
  };

  if (loading) {
    return (
      <div className="events-page">
        <section className="intro">
          <div className="container">
            <h1>Upcoming Events</h1>
            <p className="subtitle">Loading events...</p>
          </div>
        </section>
        <div className="loading-container">
          <div className="loading">Loading events...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="events-page">
        <section className="intro">
          <div className="container">
            <h1>Upcoming Events</h1>
          </div>
        </section>
        <div className="error-container">
          <div className="error-message">
            <h2>Error</h2>
            <p>{error}</p>
            <button onClick={() => window.location.reload()} className="retry-btn">
              Try Again
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="events-page" id="main-content">
      <SEOHead 
  title="Martial Arts Events & Seminars | World Budo Arts"
  description="Join World Budo Arts' upcoming martial arts events, seminars, and training sessions with Shihan Tom Callahan."
  path="/events"
/>
      <section className="intro">
        <div className="container">
          <h1>Upcoming Events</h1>
          <p className="subtitle">Join us for these exciting martial arts events and experiences</p>
        </div>
      </section>

      <section className="events-list">
        <div className="container">
          {events.length === 0 ? (
            <div className="no-events-placeholder">
              <h2>No Upcoming Events</h2>
              <p>Check back soon for new events!</p>
              <div className="no-events-actions">
                <Link to="/contact" className="btn btn-primary">Contact Us</Link>
                <Link to="/blog" className="btn btn-secondary">Read Our Blog</Link>
              </div>
            </div>
          ) : (
            events.map(event => {
              // Debug log for event data
              console.log('Event data:', event);
              
              return (
                <div key={event.id} className="event-card">
                  <div className="event-info">
                    <div className="event-details">
                      <h2>{event.attributes.name}</h2>
                      <p className="description">
                        {getDescription(event.attributes.description)}
                      </p>
                      <p className="date">
                        <strong>Date:</strong> {formatDate(event.attributes.date)}
                      </p>
                      <p className="location">
                        <strong>Location:</strong> {event.attributes.location}
                      </p>
                      <p className="price">
                        <strong>Price:</strong> ${event.attributes.price}
                      </p>
                    </div>
                  </div>
                  <div className="event-image">
                    <img src={getImageUrl(event)} alt={event.attributes.name} />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </section>
    </div>
  );
};

export default EventsPage;