import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchBlogPosts } from '../../api';
import SEOHead from '../../components/SEOHead';
import './BlogPage.css';

const BlogPage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadBlogPosts = async () => {
      try {
        const response = await fetchBlogPosts();
        if (response && response.data) {
          setBlogPosts(response.data);
        } else {
          setError('No blog posts found');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error loading blog posts:', err);
        setError(err.message || 'Failed to fetch blog posts');
        setLoading(false);
      }
    };

    loadBlogPosts();
  }, []);

  const getImageUrl = (post) => {
    if (post.attributes.image?.data?.attributes?.url) {
      return `${process.env.REACT_APP_STRAPI_URL}${post.attributes.image.data.attributes.url}`;
    }
    return '/images/blog-default.jpg'; // Fallback image
  };

  if (loading) return (
    <div className="blog-page">
      <div className="container">
        <div className="loading">Loading blog posts...</div>
      </div>
    </div>
  );

  if (error) return (
    <div className="blog-page">
      <div className="container">
        <div className="error-message">
          <h2>Error Loading Blog Posts</h2>
          <p>{error}</p>
          {error.includes('Authentication') && (
            <p>Please contact the administrator to resolve this issue.</p>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="blog-page" id="main-content">
      <SEOHead 
  title="Martial Arts Blog | World Budo Arts"
  description="Explore martial arts techniques, philosophy, and training insights from Shihan Tom Callahan and the World Budo Arts community."
  path="/blog"
/>
      <section className="intro">
        <div className="container">
          <h1>World Budo Arts Blog</h1>
          <p className="subtitle">Explore the latest insights, techniques, and stories from the world of martial arts.</p>
        </div>
      </section>
      
      <div className="blog-list-container">
        <div className="container">
          <div className="blog-list">
            {blogPosts.length === 0 ? (
              <div className="no-posts">No blog posts available at the moment.</div>
            ) : (
              blogPosts.map((post) => (
                <div key={post.id} className="blog-preview">
                  <div className="blog-image-container">
                    <img 
                      src={getImageUrl(post)}
                      alt={post.attributes.title}
                      className="blog-image"
                    />
                  </div>
                  <div className="blog-content">
                    <h2>
                      <Link to={`/blog/${post.attributes.slug}`}>{post.attributes.title}</Link>
                    </h2>
                    <p className="blog-excerpt">
                      {post.attributes.content && 
                        post.attributes.content[0]?.children?.[0]?.text.substring(0, 150) + '...'}
                    </p>
                    <Link to={`/blog/${post.attributes.slug}`} className="btn btn-primary">
                      Read More
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;