import React, { useEffect, useState } from 'react';
import './LoadingScreen.css';

const LoadingScreen = ({ onLoadingComplete }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [showHajime, setShowHajime] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHajime(true);
      // Shorter wait for hajime animation
      setTimeout(() => {
        setIsVisible(false);
        if (onLoadingComplete) onLoadingComplete();
      }, 1000); // Reduced from 2000ms to 1000ms
    }, 5000); // Reduced from 3000ms to 2000ms

    return () => clearTimeout(timer);
  }, [onLoadingComplete]);

  if (!isVisible) return null;

  return (
    <div className="loading-screen">
      <div className={`loading-content ${showHajime ? 'fade-out' : ''}`}>
        <div className="sun-circle"></div>
        <img 
          src="/logo.png" 
          alt="World Budo Arts Logo" 
          className="loading-logo"
        />
        <p className="loading-text">Loading your Budo experience...</p>
      </div>
      {showHajime && (
        <div className="hajime-container">
          <span className="hajime-text">押忍</span>
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;