import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';
import api from '../../api';
import './Footer.css';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      console.log('Submitting newsletter signup to Express backend...');
      const response = await api.post('/newsletter/signup', { email });
      console.log('Newsletter signup response:', response.data);
      setMessage('Thank you for subscribing!');
      setEmail('');
    } catch (error) {
      console.error('Newsletter signup error:', {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data,
        config: {
          url: error.config?.url,
          baseURL: error.config?.baseURL,
          method: error.config?.method
        }
      });
      
      setMessage(
        error.response?.data?.message || 
        'An error occurred. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Contact Us</h3>
            <a 
              href="mailto:shihancallahan@gmail.com" 
              className="footer-email-link"
            >
              shihancallahan@gmail.com
            </a>
            <div className="newsletter-signup">
              <h4>Subscribe to Our Newsletter</h4>
              <form onSubmit={handleNewsletterSubmit}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                  className="newsletter-input"
                  disabled={isLoading}
                  aria-label="Email input to sign-up for newsletter"
                  aria-required="true"
                />
                <button 
                  type="submit" 
                  className="btn-subscribe"
                  disabled={isLoading}
                >
                  {isLoading ? 'SUBSCRIBING...' : 'SUBSCRIBE'}
                </button>
              </form>
              {message && (
                <p className={`message ${message.includes('error') ? 'error' : 'success'}`}>
                  {message}
                </p>
              )}
            </div>
          </div>
          <div className="footer-section">
            <h3>Quick Links</h3>
            <ul>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/store">Store</Link></li>
              <li><Link to="/events">Events</Link></li>
              <li><Link to="/gallery">Gallery</Link></li>
              <li><Link to="/dashboard">Dashboard</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Follow Us</h3>
            <div className="footer-social-icons">
              <a 
                href="https://www.facebook.com/worldbudoarts" 
                target="_blank" 
                rel="noopener noreferrer"
                className="footer-social-link"
                aria-label="Facebook link for World Budo Arts"
              >
                <FaFacebook className="footer-social-icon" />
              </a>
              <a 
                href="https://www.instagram.com/world_budo_arts" 
                target="_blank" 
                rel="noopener noreferrer"
                className="footer-social-link"
                aria-label="Instagram link for World Budo Arts"
              >
                <FaInstagram className="footer-social-icon" />
              </a>
              <a 
                href="https://youtube.com/@shihantomcallahan" 
                target="_blank" 
                rel="noopener noreferrer"
                className="footer-social-link"
                aria-label="YouTube link for World Budo Arts"
              >
                <FaYoutube className="footer-social-icon" />
              </a>
              <a 
                href="https://www.tiktok.com/@tommytikkarate123" 
                target="_blank" 
                rel="noopener noreferrer"
                className="footer-social-link"
                aria-label="TikTok link for World Budo Arts"
              >
                <FaTiktok className="footer-social-icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} World Budo Arts. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;