import React, { useState } from 'react';
import api from '../../api';
import './DashboardComingSoon.css';

const DashboardComingSoon = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      console.log('Submitting newsletter signup to Express backend...');
      const response = await api.post('/newsletter/signup', { email });
      console.log('Newsletter signup response:', response.data);
      setMessage('Thank you for subscribing!');
      setEmail('');
    } catch (error) {
      console.error('Newsletter signup error:', {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data,
        config: {
          url: error.config?.url,
          baseURL: error.config?.baseURL,
          method: error.config?.method
        }
      });
      
      setMessage(
        error.response?.data?.message || 
        'An error occurred. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="dashboard-coming-soon" id="main-content">
      <section className="intro">
        <div className="container">
          <h1>World Budo Arts Dashboard</h1>
          <p className="subtitle">Coming Soon</p>
        </div>
      </section>
      <div className="coming-soon-content">
        <div className="container">
        <h2>Thank you for your patience</h2>
          <p>We're putting the finishing touches on your personalized martial arts dashboard.</p>
          <p>Sign up for our newsletter to be notified when we launch!</p>
          <form onSubmit={handleNewsletterSubmit} className="newsletter-form">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              disabled={isLoading}
              aria-label="Email input to sign-up for newsletter"
              aria-required="true"
            />
            <button type="submit" disabled={isLoading}>
              {isLoading ? 'Signing Up...' : 'Sign Up'}
            </button>
          </form>
          {message && <p className={`message ${message.includes('error') ? 'error' : 'success'}`}>{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default DashboardComingSoon;