import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import api from '../../api';
import './ContactPage.css';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [newsletterMessage, setNewsletterMessage] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setIsSubmitted(true);
    setTimeout(() => setIsSubmitted(false), 3000);
  };

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      console.log('Submitting newsletter signup to Express backend...');
      const response = await api.post('/newsletter/signup', { email });
      console.log('Newsletter signup response:', response.data);
      setMessage('Thank you for subscribing!');
      setEmail('');
    } catch (error) {
      console.error('Newsletter signup error:', {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data,
        config: {
          url: error.config?.url,
          baseURL: error.config?.baseURL,
          method: error.config?.method
        }
      });
      
      setMessage(
        error.response?.data?.message || 
        'An error occurred. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="contact-page" id="main-content">
      <section className="intro">
        <div className="container">
          <h1>Contact Us</h1>
          <p className="subtitle">Get in touch with World Budo Arts</p>
        </div>
      </section>

      <div className="container">
        <div className="contact-content">
          <div className="contact-form-container">
            <h2>Send Us a Message</h2>
            <form onSubmit={handleSubmit} className="contact-form">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  aria-label="Name input to send a message to Shihan Tom"
                  aria-required="true"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  aria-label="Email input to send a message to Shihan Tom"
                  aria-required="true"
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleInputChange}
                  required
                  aria-label="Subject input to send a message to Shihan Tom"
                  aria-required="true"
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  aria-label="Message input to send a message to Shihan Tom"
                  aria-required="true"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">Send Message</button>
            </form>
            {isSubmitted && (
              <div className="submission-message">
                Thank you for your message. We'll get back to you soon!
              </div>
            )}
          </div>

          <div className="contact-info">
            <h2>Contact Information</h2>
            <div className="info-items">
              <div className="info-item">
                <FaEnvelope className="info-icon" />
                <a href="mailto:shihancallahan@gmail.com">shihancallahan@gmail.com</a>
              </div>
            </div>

            <div className="social-links">
              <h3>Follow Us</h3>
              <div className="social-icons">
                <a href="https://facebook.com/worldbudoarts" target="_blank" rel="noopener noreferrer" aria-label="Facebook link for World Budo Arts">
                  <FaFacebook />
                </a>
                <a href="https://instagram.com/world_budo_arts" target="_blank" rel="noopener noreferrer" aria-label="Instagram link for World Budo Arts">
                  <FaInstagram />
                </a>
                <a href="https://youtube.com/@shihantomcallahan" target="_blank" rel="noopener noreferrer" aria-label="YouTube link for World Budo Arts">
                  <FaYoutube />
                </a>
              </div>
            </div>

            <div className="newsletter-signup">
    <h3>Subscribe to Our Newsletter</h3>
    <p>Stay updated with our latest news and events</p>
    <form onSubmit={handleNewsletterSubmit}>
      <div className="newsletter-input">
        <input
          type="email"
          value={newsletterEmail}
          onChange={(e) => setNewsletterEmail(e.target.value)}
          placeholder="Enter your email"
          required
          disabled={isLoading}
          aria-label="Email input to sign-up for newsletter"
          aria-required="true"
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Subscribing...' : 'Subscribe'}
        </button>
      </div>
    </form>
    {newsletterMessage && (
      <div className={`newsletter-message ${newsletterMessage.includes('error') ? 'error' : 'success'}`}>
        {newsletterMessage}
      </div>
    )}
  </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;