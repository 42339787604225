import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SEOHead from '../../components/SEOHead';
import { fetchGalleryItems } from '../../api';
import './GalleryPage.css';

const GalleryCard = ({ item }) => {
    const [isFlipped, setIsFlipped] = useState(false);

  const getImageUrl = (item) => {
    if (item.attributes?.image?.data?.attributes?.url) {
      return `${process.env.REACT_APP_STRAPI_URL}${item.attributes.image.data.attributes.url}`;
    } else if (item.image?.url) {
      return `${process.env.REACT_APP_STRAPI_URL}${item.image.url}`;
    }
    return '/placeholder-image.jpg'; // Fallback image
  };

  return (
    <div className="gallery-card-container">
      <div 
        className={`gallery-card ${isFlipped ? 'is-flipped' : ''}`}
        onClick={() => setIsFlipped(!isFlipped)}
      >
        <div className="gallery-card-face gallery-card-front" role="button" tabIndex="0">
          <img 
            src={getImageUrl(item)} 
            alt={item.attributes?.title || item.title || 'Gallery Image'} 
          />
          <div className="gallery-card-overlay">
            <h3>{item.attributes?.title || item.title}</h3>
            <p>Click for more details</p>
          </div>
        </div>
        <div className="gallery-card-face gallery-card-back" role="button" tabIndex="0">
          <div className="gallery-card-content">
            <h3>{item.attributes?.title || item.title}</h3>
            <div className="gallery-card-scroll">
              <p>{item.attributes?.story || item.story}</p>
            </div>
            <span className="gallery-card-close">Click to close</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const GalleryPage = () => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadGalleryItems = async () => {
      try {
        const data = await fetchGalleryItems();
        console.log('Fetched gallery items:', data); // Debug log
        setGalleryItems(Array.isArray(data) ? data : []);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching gallery items:', err);
        setError(err.message || 'Failed to fetch gallery items');
        setLoading(false);
      }
    };

    loadGalleryItems();
    window.scrollTo(0, 0);
  }, []);

  if (loading) return (
    <div className="gallery-page">
      <div className="container">
        <div className="loading">Loading gallery...</div>
      </div>
    </div>
  );

  if (error) return (
    <div className="gallery-page">
      <div className="container">
        <div className="error">{error}</div>
      </div>
    </div>
  );

  return (
    <div className="gallery-page" id="main-content">
      <SEOHead 
        title="Gallery | World Budo Arts"
        description="Explore our collection of martial arts moments and memories at World Budo Arts."
        path="/gallery"
      />
      <section className="intro">
        <div className="container">
          <h1>Gallery</h1>
          <p className="subtitle">Journey through our martial arts moments</p>
        </div>
      </section>

      <section className="gallery-description">
  <div className="container">
    <h2>Welcome to Our Journey</h2>
    <p>
      As the founder of World Budo Arts, I'm proud to share with you this collection of moments that capture our martial arts journey. Each image tells a story of dedication, growth, and the unbreakable bonds formed through our practice.
    </p>
    <p>
      These photographs represent more than just events - they're milestones in our continuing evolution as martial artists and as a community.
    </p>
    <Link to="/about" className="gallery-signature">
  ~ Shihan Tom Callahan
</Link>
    <div className="gallery-instructions">
      Hover over images to preview, and click to read the full story behind each moment.
    </div>
  </div>
</section>

      <section className="gallery-content">
        <div className="container">
          <div className="gallery-grid">
            {galleryItems.length > 0 ? (
              galleryItems.map((item) => (
                <GalleryCard key={item.id} item={item} />
              ))
            ) : (
              <div className="no-items">No gallery items found</div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default GalleryPage;