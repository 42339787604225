import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SEOHead from '../../components/SEOHead';
import './AboutPage.css';

const AboutPage = () => {
  const [isPodcastHovering, setIsPodcastHovering] = useState(false);
  const [isAffiliationHovering, setIsAffiliationHovering] = useState(false);
  const [selectedAffiliation, setSelectedAffiliation] = useState(null);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  const founderPhotos = [
    "/images/shihan-tom-callahan-1.jpg",
    "/images/shihan-tom-callahan-2.jpg",
    "/images/shihan-tom-callahan-3.jpg",
    "/images/shihan-tom-callahan-4.jpg"
  ];

  const podcasts = [
    { id: 1, url: 'https://youtu.be/d7-4TTZcBOk' },
    { id: 2, url: 'https://youtu.be/I-BaQlK0Z38' },
    { id: 3, url: 'https://youtu.be/27ofLxq_-IQ' },
    { id: 4, url: 'https://youtu.be/XZP0P8Hu52U' },
    { id: 5, url: 'https://youtu.be/n4MVYHm-UrQ' },
    { id: 6, url: 'https://youtu.be/yDIQndcBjN8' },
    { id: 7, url: 'https://youtu.be/g-0Ylx3j0uU' }
  ];

  const affiliatePartners = [
    { id: 1, image: '/images/tom-with-kaicho.jpg', title: 'Shihan Tom & Kaicho Royama', description: 'A meeting of minds: Shihan Tom Callahan with Kaicho Hatsuo Royama' },
    { id: 2, image: '/images/kyokushin-kan-logo.png', title: 'Kyokushin-Kan Logo', description: 'Official logo of the Kyokushin-Kan Karate organization' },
    { id: 3, image: '/images/kaicho-black-belt-magazine.jpg', title: 'Kaicho on Black Belt Magazine', description: 'Kaicho Hatsuo Royama featured on the cover of Black Belt Magazine' },
    { id: 4, image: '/images/placeholder4.jpg', title: 'Lorem Ipsum 4', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    { id: 5, image: '/images/placeholder5.jpg', title: 'Lorem Ipsum 5', description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
    { id: 6, image: '/images/placeholder6.jpg', title: 'Lorem Ipsum 6', description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.' },
    { id: 7, image: '/images/placeholder7.jpg', title: 'Lorem Ipsum 7', description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.' },
    { id: 8, image: '/images/placeholder8.jpg', title: 'Lorem Ipsum 8', description: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.' },
    { id: 9, image: '/images/placeholder9.jpg', title: 'Lorem Ipsum 9', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.' },
    { id: 10, image: '/images/placeholder10.jpg', title: 'Lorem Ipsum 10', description: 'Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim.' },
  ];

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
        }
      });
    }, observerOptions);

    const timelineItems = document.querySelectorAll('.timeline-item');
    timelineItems.forEach(item => observer.observe(item));

    return () => {
      timelineItems.forEach(item => observer.unobserve(item));
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % founderPhotos.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [founderPhotos.length]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  const getYouTubeVideoId = (url) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };

  return (
    <div className="about-page" id="main-content">
      <SEOHead 
  title="About World Budo Arts | Shihan Tom Callahan"
  description="Learn about Shihan Tom Callahan and World Budo Arts' philosophy of martial arts training. Discover our commitment to authentic martial arts instruction."
  path="/about"
/>
    <section className="intro">
      <div className="container">
        <h1>About World Budo Arts</h1>
        <p className="subtitle">Mastering the Way of Budo</p>
      </div>
    </section>

    <section className="founder-and-philosophy">
        <div className="container">
          <div className="founder-content">
            <div className="founder-image">
              <div className="image-container">
                {founderPhotos.map((photo, index) => (
                  <img
                    key={index}
                    src={photo}
                    alt={`Shihan Tom Callahan ${index + 1}`}
                    className={`founder-photo ${index === currentPhotoIndex ? 'active' : 'inactive'}`}
                  />
                ))}
              </div>
            </div>
            <div className="founder-bio">
            <h2>Kyoshi Shihan Tom Callahan</h2>
            <h3>6th Dan, Founder of World Budo Arts</h3>
              <p>
              Shihan Callahan's martial arts journey began in 1972 in Uechi-Ryu Karate under Al Wharton and Mattson Academy.
              In addition to his Kyokushin Karate 6th dan and Kyoshi Shihan title under Kaicho Hatsuo Royama, he has supplemented his skills with various styles including Krav Maga under Roy Elghanayan and Jukkendo under Nori Bunaswa.
              </p>
              <p>
                As the Vice-Chairman "All-Americas" and International Committee Secretary for Kyokushin-Kan, 
                Shihan Callahan continues to expand his knowledge and skills, passing them on to the next generation 
                through World Budo Arts.
              </p>
              <p>
                Throughout his career, Shihan Callahan has competed in numerous tournaments, earning accolades and 
                respect from peers worldwide. His dedication to the martial arts extends beyond physical training, 
                emphasizing the importance of mental discipline and spiritual growth.
              </p>
              <p>
                In founding World Budo Arts, Shihan Callahan aims to create a global community of martial artists 
                united by the principles of Budo. His vision is to preserve traditional martial arts while adapting 
                to the needs of modern practitioners, fostering an environment where students can grow both as 
                martial artists and as individuals.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="timeline">
        <div className="container">
          <h2>Our Journey</h2>
          <div className="timeline-container">
            <div className="timeline-item">
              <div className="timeline-content">
                <h3>1972</h3>
                <p>Shihan Tom Callahan begins his martial arts journey in Uechi-Ryu Karate</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-content">
                <h3>1990s</h3>
                <p>Return to martial arts, focusing on Kyokushinkai Karate</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-content">
                <h3>2008</h3>
                <p>Transition from IKO-1 to Kyokushin-Kan Organization</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-content">
                <h3>2009</h3>
                <p>Founding of World Budo Arts, expanding the philosophy of Budo globally</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-content">
                <h3>2023</h3>
                <p>Promoted to Rokudan in Kyokushin</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-content">
                <h3>Present</h3>
                <p>Expanding World Budo Arts globally and teaching all over the world</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="podcasts">
        <div className="container">
          <h2>Our Podcasts</h2>
          <div 
            className="podcast-scroll" 
            onMouseEnter={() => setIsPodcastHovering(true)}
            onMouseLeave={() => setIsPodcastHovering(false)}
            aria-label="Automatic carousel of videos"
          >
            <div className={`podcast-container ${isPodcastHovering || selectedAffiliation ? 'paused' : ''}`}>
              {podcasts.concat(podcasts).map((podcast, index) => (
                <div key={`${podcast.id}-${index}`} className="podcast-item">
                  <a href={podcast.url} target="_blank" rel="noopener noreferrer" aria-label="Link to external YouTube podcast">
                    <div className="thumbnail-container">
                      <img 
                        src={`https://img.youtube.com/vi/${getYouTubeVideoId(podcast.url)}/0.jpg`} 
                        alt={`Podcast ${podcast.id}`}
                      />
                      <div className="play-button"></div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="affiliations">
        <div className="container">
          <h2>Our Affiliations</h2>
          <p>
            World Budo Arts is proud to be affiliated with Kaicho Hatsuo Royama and Kancho Hiroto Okazaki's 
            Kyokushin-Kan Karate do Renmei, based in Saitama, Japan.
          </p>
          <div 
            className="affiliation-scroll"
            onMouseEnter={() => setIsAffiliationHovering(true)}
            onMouseLeave={() => setIsAffiliationHovering(false)}
            aria-label="Automatic carousel of affiliation images"
          >
            <div className={`affiliation-container ${isAffiliationHovering || selectedAffiliation ? 'paused' : ''}`}>
              {affiliatePartners.concat(affiliatePartners).map((partner, index) => (
                <div 
                  key={`${partner.id}-${index}`} 
                  className="affiliation-item"
                  onClick={() => setSelectedAffiliation(partner)}
                  role="button"
                  tabIndex="0"
                >
                  <img src={partner.image} alt={partner.title} />
                  <div className="affiliation-overlay">
                    <h3 className="affiliation-title">{partner.title}</h3>
                    <p className="affiliation-description">{partner.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {selectedAffiliation && (
        <div className="modal" onClick={() => setSelectedAffiliation(null)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="modal-close" onClick={() => setSelectedAffiliation(null)}>&times;</span>
            <h3 className="modal-title">{selectedAffiliation.title}</h3>
            <img src={selectedAffiliation.image} alt={selectedAffiliation.title} className="modal-image" />
            <p className="modal-description">{selectedAffiliation.description}</p>
          </div>
        </div>
      )}

      <section className="cta">
        <div className="container">
          <h2>Join Our Community</h2>
          <p>Experience the transformative power of Budo and become part of our global martial arts family.</p>
          <Link to="/dashboard" className="btn btn-primary">Explore Our Lessons</Link>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;