import React from 'react';
import { Helmet } from "react-helmet-async";

const SEOHead = ({ 
  title = "World Budo Arts - Martial Arts Training & Philosophy",
  description = "Learn authentic martial arts with Shihan Tom Callahan. Offering Kyokushin Karate, private lessons, events, and equipment. Join our global martial arts community.",
  path = ""
}) => {
  const baseUrl = "https://worldbudoarts.com"; // Replace with actual domain
  const currentUrl = `${baseUrl}${path}`;
  
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={`${baseUrl}/images/wba-social-share.png`} />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={currentUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${baseUrl}/images/wba-social-share.png`} />
      
      {/* Additional SEO tags */}
      <meta name="keywords" content="martial arts, karate, kyokushin, budo, martial arts training, Tom Callahan, self defense, martial arts philosophy" />
      <link rel="canonical" href={currentUrl} />
      
      {/* Language */}
      <html lang="en" />
    </Helmet>
  );
};

export default SEOHead;