import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="container">
        <div className="header-content">
          <Link to="/" className="logo">
            <img src="/logo.png" alt="World Budo Arts" />
          </Link>
          {isMobile ? (
            <>
              <button className="menu-toggle" onClick={toggleMenu}>
                ☰
              </button>
              <nav className={`main-nav ${isMenuOpen ? 'open' : ''}`} aria-haspopup="true">
                <ul>
                  <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
                  <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
                  <li><Link to="/blog" onClick={toggleMenu}>Blog</Link></li>
                  <li><Link to="/store" onClick={toggleMenu}>Store</Link></li>
                  <li><Link to="/events" onClick={toggleMenu}>Events</Link></li>
                  <li><Link to="/dashboard" onClick={toggleMenu}>Dashboard</Link></li>
                  <li><Link to="/gallery" onClick={toggleMenu}>Gallery</Link></li>
                  <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
                  <li>
                    <a 
                      href="https://app.store.link" 
                      className="btn btn-primary" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      onClick={toggleMenu}
                      aria-label="Link to download the World Budo Arts app - coming soon"
                    >
                      Download App
                    </a>
                  </li>
                </ul>
              </nav>
            </>
          ) : (
            <>
              <nav className="main-nav">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/blog">Blog</Link></li>
                  <li><Link to="/store">Store</Link></li>
                  <li><Link to="/events">Events</Link></li>
                  <li><Link to="/dashboard">Dashboard</Link></li>
                  <li><Link to="/gallery">Gallery</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                </ul>
              </nav>
              <a 
                href="https://app.store.link" 
                className="btn btn-primary" 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label="Link to download the World Budo Arts app - coming soon"
              >
                Download App
              </a>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;